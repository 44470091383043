.text {
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-h3);
  padding-bottom: 0.75rem;
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .text {
    font-size: 1rem;
  }
}
