.container {
  padding-top: 1rem;
  margin-bottom: 4rem;
}

.header {
  /* text-align: center; */
  /* margin-bottom: 2rem !important; */
  /* text-decoration: underline; */
  margin-bottom: 0.75rem;
}

.subHeader {
  margin-bottom: 2rem !important;
}

.serviceCategoriesContainer {
  margin-bottom: 4rem;
}

.divider {
  width: 100%;
  height: 0.2rem;
  background-color: var(--color-primary);
  margin-bottom: 2rem;
}
