.badge {
  position: absolute;
  top: -10px;
  right: -8px;
  padding: 0.3rem 0.7rem;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 600;

  opacity: 0; /* Start hidden */
  transform: scale(0.7); /* Start slightly smaller */
  transition: opacity 0.2s ease, transform 0.2s ease !important;
}

.mostPopularBadge {
  background-color: var(--color-primary);
  color: var(--color-brand-1000);
}
.newsBadge {
  background-color: var(--color-hover-100);
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
}

.showBadge {
  opacity: 1;
  transform: scale(1);
}
