.container {
  margin-bottom: 4rem;
}

.clinicButtonContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 14px;
  row-gap: 8px;
}

.title {
  color: var(--color-text-foreground);
}

@media (max-width: 768px) {
  .clinicButtonContainer {
    grid-template-columns: 1fr 1fr;
  }
}

.divider {
  width: 100%;
  height: 0.2rem;
  background-color: var(--color-primary);
  margin-bottom: 1rem;
}
