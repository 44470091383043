.button {
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  background-color: white;
  border-radius: 100px;
  display: block;
  color: var(--color-primary);
  position: relative;
  border: none;
  cursor: pointer;
  text-align: left;
}

.button:before {
  content: '';
  position: absolute;
  top: 0;
  left: -25px;
  transform: rotate(180deg);
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-position: center left;
  background-image: url('../../../assets/images/Desktop_Arrow_Blue.svg');
  background-repeat: no-repeat;
}

.button:hover {
  opacity: 0.8;
}

.text {
  line-height: calc(var(--size-form-height) - 0.5rem);
  margin: 0;
  display: inline-block;
  width: auto;
  opacity: 0.8;
}
