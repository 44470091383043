.h1 {
  font-size: var(--font-size-h1);
  margin-bottom: 1rem;
  color: var(--color-text-foreground);
}

@media (max-width: 640px) {
  .h1 {
    font-size: var(--font-size-h2);
  }
}
