.infoModal {
  font-size: var(--font-size-body);
  font-weight: var(--font-weight-light);
  display: none;
  bottom: 50px;
  right: 0;
  width: 314px;
  padding: 1rem;
  background-color: var(--color-primary);
  color: var(--color-brand-1000);
  border-radius: 8px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
  margin-left: 50px;
  position: relative;
  z-index: 999;
}

/* Triangle under the infoModal*/

.infoModal:before {
  content: '';
  /*The right value must be calculated with: (top value of after) - (top value of before) = (right value of before) */
  /*example: (-4px) - (-7px) = 3px*/
  right: 145px;
  /*The `top` value must be identical to border-width*/
  bottom: -15px;
  width: 0;
  height: 0;
}
.infoModal:after {
  content: '';
  right: 148px;
  bottom: -11px;
  width: 0;
  height: 0;
}
