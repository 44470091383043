.container {
  max-width: 600px;
  margin: 0 auto;
}

.title {
  text-align: center;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.loginButton {
  margin-left: 2rem !important;
  background-color: var(--color-brand-100);
  color: var(--color-brand-1000);
  margin-bottom: 0 !important;
}

.memberLink {
  margin-right: 2rem;
  color: var(--color-primary);
}

@media (max-width: 768px) {
  .loginButton {
    margin-left: 0;
    margin-bottom: 2rem;
  }

  .buttonContainer {
    flex-direction: column-reverse;
  }

  .loginButton {
    margin-left: 0 !important;
    margin-bottom: 2rem !important;
  }
}
