.container {
  max-width: 600px;
  padding-bottom: 2rem;
  padding-top: 1rem;
}

.sectionTitle {
  border: 0 !important;
  text-align: center;
  margin-bottom: 1rem;
  animation-duration: 1.2s;
  animation-name: slideIn;
  animation-timing-function: ease;
}

.footerMenuContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0;
}

@keyframes slideIn {
  from {
    margin-left: -30px;
  }

  to {
    margin-left: 0%;
  }
}
