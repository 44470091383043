@import './variables.module.css';
@import './fonts.module.css';

html,
button {
  font-family: var(--font-family);
  font-size: var(--font-size-base);
}

body {
  -webkit-font-smoothing: antialiased;
  margin: 0;
  background-color: var(--color-brand-1000);
}
