.callToAction {
  font-size: var(--font-size-body);
  font-weight: var(--font-weight-medium);
  overflow: hidden;
  outline: none;
  text-decoration: none;
  display: inline-block;
  border: none;
  transition: all 0.3s ease;
  line-height: 1rem;
}

.callToAction:hover {
  cursor: pointer;
}

.sizeNormal {
  padding: 0.5rem 1.2rem;
  border-radius: 1rem;
}

.sizeLarge {
  padding: 1rem 4rem;
  border-radius: 2rem;
}

.themeLight.primary {
  background-color: var(--color-primary);
  color: var(--color-brand-1000);
}

.themeLight.light {
  background-color: var(--color-light-100);
  color: var(--color-primary);
}

.themeLight.secondary {
  background-color: var(--color-light-100);
  color: var(--color-brand-100);
}

.themeLight.secondary:not([disabled]):hover {
  color: var(--color-primary);
  background-color: var(--color-brand-400);
}

/* button1 */
.themeLight.tertiary {
  background-color: var(--color-brand-100);
  color: var(--color-brand-1000);
}

.themeLight.tertiary:not([disabled]):hover {
  color: var(--color-brand-100);
  background-color: transparent;
  box-shadow: 0px 0px 0px 3px var(--color-brand-100) inset;
}

/* button2 */
.themeLight.quaternary {
  background-color: var(--color-brand-100);
  color: var(--color-brand-1000);
}

.themeLight.quaternary:not([disabled]):hover {
  box-shadow: 0px 0px 0px 3px var(--color-brand-100) inset;
  background-color: transparent;
}

/* button3 */
.themeLight.outlinedSecondary {
  background-color: transparent;
  box-shadow: 0px 0px 0px 3px var(--color-brand-100) inset;
  color: var(--color-brand-1000);
}

.themeLight.outlinedSecondary:not([disabled]):hover {
  background-color: var(--color-brand-100);
  color: var(--color-brand-1000);
}

.themeDark.outlinedSecondary {
  background-color: transparent;
  box-shadow: 0px 0px 0px 3px var(--color-brand-100) inset;
  color: var(--color-brand-100);
}

.themeDark.outlinedSecondary:not([disabled]):hover {
  background-color: var(--color-brand-100);
  color: var(--color-brand-1000);
}

/* button4 */
.themeLight.quinary {
  background-color: var(--color-brand-1000);
  color: var(--color-primary);
}

.themeLight.quinary:not([disabled]):hover {
  color: var(--color-brand-1000);
  box-shadow: 0px 0px 0px 3px var(--color-brand-1000) inset;
  background-color: transparent;
}

/* button5*/
.themeLight.outlinedQuaternary {
  background-color: transparent;
  box-shadow: 0px 0px 0px 3px var(--color-primary) inset;
  color: var(--color-primary);
}

.themeLight.outlinedQuaternary:not([disabled]):hover {
  background-color: var(--color-primary);
  box-shadow: 0px 0px 0px 3px var(--color-primary) inset;
  color: var(--color-brand-1000);
}

.disabled {
  background-color: var(--color-light-200) !important;
  color: var(--color-light-400) !important;
}

.themeLight.outlinedQuaternary:disabled {
  opacity: 0.4 !important;
  pointer-events: none !important;
}

.themeLight.outlined {
  background-color: transparent;
  color: var(--color-primary);
  box-shadow: 0px 0px 0px 3px var(--color-primary) inset;
}

.themeLight.outlined:not([disabled]):hover {
  background-color: var(--color-primary);
  color: var(--color-brand-1000);
}

.themeBrown.primary {
  background-color: var(--color-brand-800);
  color: var(--color-light-100);
}

.themeBrown.primary:not([disabled]):hover {
  background-color: var(--color-primary);
}

.themeBrown.primary {
  background-color: var(--color-brand-800);
  color: var(--color-light-100);
}

.themeBrown.primary:not([disabled]):hover {
  background-color: var(--color-primary);
}

.themeDark.outlined {
  background-color: transparent;
  box-shadow: 0px 0px 0px 3px var(--color-primary) inset;
  color: var(--color-primary);
}

.themeDark.outlined:not([disabled]):hover {
  background-color: var(--color-primary);
  color: var(--color-light-100);
}

.themeLight.noline {
  background-color: transparent;
  color: var(--color-light-100);
}

.themeLight.noline:not([disabled]):hover {
  background-color: var(--color-light-100);
  color: var(--color-primary);
}

.themeDark.noline {
  background-color: transparent;
  color: var(--color-primary);
}

.themeDark.noline:not([disabled]):hover {
  background-color: var(--color-primary);
  color: var(--color-light-100);
}
