.button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-shrink: 0;
  border-radius: 1rem;
  border: solid;
  border-width: 2px;
  border-color: var(--color-primary) !important;
  width: 4.5rem;
  height: 8rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin: 0.5rem;
}

.button:hover {
  cursor: pointer;
}

.dateText {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-h3);
}

.unselected {
  background-color: transparent;
  color: var(--color-primary);
}

.unselected:hover {
  background-color: var(--color-primary);
  color: var(--color-brand-1000);
}

.selected {
  background-color: var(--color-primary);
  color: var(--color-brand-1000);
}
.unavailable {
  background-color: transparent;
  color: var(--color-primary);
  opacity: 0.25;
}

.unavailable:hover {
  cursor: default;
}

.unavailableSelected {
  background-color: var(--color-primary);
  color: var(--color-brand-1000);
  opacity: 0.4;
}

.unavailableSelected:hover {
  cursor: default;
}
