.container {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  margin-bottom: 0.75rem;
  border-bottom: 1px solid var(--color-primary);
}

.isOpen {
  padding-bottom: 2rem;
  border-bottom: none;
}

.isOpened {
  height: auto;
}

.typeHeaderContainer {
  cursor: pointer;
  display: flex;
  position: relative;
  margin-bottom: 1em;
}

.typeHeader {
  margin-bottom: 0;
  display: block;
  width: 100%;
  color: var(--color-text-foreground);
  user-select: none;
}

.arrow {
  position: absolute;
  right: 0;
  top: 5px;
  background-image: url('../../../../../assets/images/Desktop_Arrow_Brown.svg');
  background-repeat: no-repeat;
  height: 22px;
  width: 22px;
  background-size: contain;
  transition: all 0.5s;
  color: var(--color-text-foreground);
}

.menuOpenedRotateIcon {
  transform: rotate(90deg);
}

.menuClosedRotateIcon {
  transform: rotate(-90deg);
}

/* TODO: Fix overflow of most popular badge */
.animateContainer {
  width: 100%;
}

.categoryInfoBox {
  color: var(--color-text-foreground);
  background-color: var(--color-secondary);
  padding: 0.75rem;
  border-radius: 0.25rem;
  margin-bottom: 1.5rem;
}

.categoryInfoBox > * {
  margin: 0 !important;
}

.categoryInfoBoxHeader {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
