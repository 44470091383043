.container {
  background-color: white;
  height: var(--size-form-height);
  display: flex;
  border-radius: var(--size-form-height);
  margin-bottom: 1rem;
  box-sizing: border-box;
  width: 100%;
}
.primary {
  color: var(--color-primary);
}

.primary::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--color-primary);
  opacity: 0.4;
}

.secondary {
  color: var(--color-primary);
}

.secondary::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--color-primary);
  opacity: 0.4;
}

.uppercase {
  text-transform: uppercase;
  font-size: var(--font-size-body) !important;
  color: var(--color-primary);
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.uppercase::placeholder {
  text-transform: none;
  color: var(--color-primary);
  opacity: 0.4;
}

.input {
  padding: 0 1rem;
  outline: none;
  border: none;
  background-color: transparent;
  width: 100%;
  font-size: var(--font-size-body);
  font-family: var(--font-family);
}

.checkbox {
  height: var(--size-form-height);
  accent-color: var(--color-primary);
  transform: scale(1.5);
  margin-right: 0.5rem;
}

.checkboxContainer {
  display: flex;
  align-items: center;
  border-color: var(--color-primary);
}

.checkboxLabel {
  font-size: 14px;
  color: var(--color-primary);
  font-weight: 400;
}

input:-webkit-autofill {
  -webkit-text-fill-color: var(--color-primary) !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 33px white inset !important;
  border-radius: var(--size-form-height);
  background-color: white;
}

/* insert fake placeholder*/
input[type='date']::before {
  content: attr(placeholder);
  opacity: 0.4;
  width: 100%;
}

/* hide our custom/fake placeholder text when in focus to show the default
 * 'mm/dd/yyyy' value and when valid to show the users' date of birth value.
 */
input[type='date']:focus::before,
input[type='date']:valid::before {
  display: none;
}
