.container {
  background-color: var(--color-brand-1000);
  padding-top: 1rem;
}

.createOrUpdateBookingButton {
  margin: 2rem 0;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 4rem;
}

.loadingWrapper {
  margin-top: 2rem;
  margin-bottom: 3rem;
}

.deleteContainer {
  margin: 2rem 0;
}

.deleteConfirmationText {
  text-align: center;
  color: var(--color-primary);
  margin-bottom: 0.5rem !important;
}

.updateAndDeleteButtonsContainer {
  min-height: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 5rem;
}

.deleteButtonsContainer {
  display: flex;
  justify-content: space-evenly;
}

.loginPageContainer {
  max-width: 600px;
  margin: 2rem 0;
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loginOptions {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
}

.loginOptionButton {
  margin-bottom: 1rem;
}

.loginOptionContainer {
  margin-top: 2rem;
}

.text {
  text-align: center;
  color: var(--color-primary);
}

.deleteButtonText {
  color: var(--color-primary);
  cursor: pointer;
  user-select: none;
}

.invalidSecitons {
  display: flex;
  flex-direction: column;
  margin: 1rem 2rem;
  cursor: pointer;
}

.invalidText {
  color: var(--color-brand-100);
  margin: 0 !important;
}

@media (max-width: 576px) {
  .deleteButtonText {
    margin: 1rem auto;
  }
}

.twoButtonWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 1rem;
}

.bookButton {
  display: flex;
  flex: 1;
  justify-content: center;
  background-color: var(--color-brand-1000);
  color: var(--color-primary);
  border: none;
  padding: 1rem;
  border-radius: 5rem;
  &:hover {
    cursor: pointer;
  }
}

/* BOOK BUTTON */
.bookSectionContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
