.calendarIconWrappers {
  margin: 1rem;
  cursor: pointer;
}
.homeButton {
  margin-top: 1rem;
}

.calendarWrapper {
  /* min-height: 8rem; */
  margin-bottom: 2rem;
}
