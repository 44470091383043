.header {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--color-primary);
  border-bottom: 1px solid var(--color-primary);
  align-items: center;
  padding: 0.5rem 0;
}

.text {
  padding-bottom: 0 !important;
  cursor: pointer;
  user-select: none;
}

.activeTab {
  font-weight: var(--font-weight-bold);
}

.guestTab {
  cursor: pointer;
  user-select: none;
  width: 100%;
  font-weight: var(--font-weight-bold);
  padding-bottom: 0.5rem !important;
  border-bottom: 1px solid var(--color-primary);
}

.tabMenuContentContainer {
  margin-top: 2rem;
}
