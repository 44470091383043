.container {
  margin-bottom: 4rem;
}

.title {
  color: var(--color-text-foreground);
}

.resourcesContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.button {
  width: 48%;
  margin-bottom: 1rem;
  white-space: nowrap;
}

.buttonFullWidth {
  width: 100%;
}

.divider {
  width: 100%;
  height: 0.2rem;
  background-color: var(--color-primary);
  margin-bottom: 1rem;
}

.loadingIndicator {
  color: var(--color-brand-100);
}
