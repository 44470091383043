.h2 {
  font-size: var(--font-size-h2);
  font-weight: var(--font-weight-bold);
  margin-bottom: 1rem;
  color: var(--color-text-foreground);
}

@media (max-width: 640px) {
  .h2 {
    font-size: var(--font-size-h3);
  }
}
