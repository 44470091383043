.infoSymbol {
  height: 18px;
  width: 18px;
  font-weight: var(--font-weight-medium);
  text-align: center;
  border: solid;
  border-radius: 100px;
  border-width: 1px;
  border-color: var(--color-primary);
  background-image: url('../../../assets/images/info_icon.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 2.5px;
}

.infoSymbolSelected {
  background-image: url('../../../assets/images/info_icon_white.svg');
}

.infoSymbol:hover .infoModal {
  display: block;
}

.infoMobileMobile {
  height: 100%;
  width: 100%;
}

.modalContent > :first-child {
  margin-top: 0;
  text-overflow: ellipsis;
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
}
