.container {
  margin-bottom: 4rem;
}

.title {
  color: var(--color-primary);
}

.notice {
  margin-bottom: 1em;
  font-style: italic;
  color: var(--color-primary);
}
.invalidText {
  display: block;
  color: var(--color-brand-100);
  margin: 0 !important;
}
