@font-face {
  font-family: 'GT-Walsheim';
  font-style: normal;
  src: url('../fonts/GT-Walsheim-Light.woff2') format('woff2'),
    url('../fonts/GT-Walsheim-Light.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'GT-Walsheim';
  src: url('../fonts/GT-Walsheim-Bold.woff2') format('woff2'),
    url('../fonts/GT-Walsheim-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'GT-Walsheim';
  src: url('../fonts/GT-Walsheim-Medium.woff2') format('woff2'),
    url('../fonts/GT-Walsheim-Medium.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'GT-Walsheim';
  font-style: normal;
  src: url('../fonts/GT-Walsheim-Regular.woff2') format('woff2'),
    url('../fonts/GT-Walsheim-Regular.woff') format('woff');
  font-display: swap;
}
