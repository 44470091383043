.container {
  /* background-color: var(--color-brand-100); */
  border-radius: 30px;
  color: var(--color-light-100);
}

.bookingOverviewContainer {
  background-color: var(--color-primary);
  border-radius: 30px;
  color: var(--color-light-100);
  padding: 2rem 2rem;
}

.serviceRow {
  display: flex;
  justify-content: space-between;
}

.hiddenStripeForm {
  display: none;
}

.checkbox {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}

.treatmentNameContainer {
  display: flex;
  flex-direction: column;
}

.childTreatment {
  margin-left: 1rem;
}

.title {
  margin-bottom: 2rem;
  margin-top: 2.625rem;
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.listItem {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}

.removeButton {
  background-color: transparent;
  border: none;
  background-image: url('../../../../assets/images/Desktop_Cross_White.svg');
  background-position: center right;
  background-repeat: no-repeat;
  background-size: 12px 12px;
}

.removeButton:hover {
  cursor: pointer;
}

.promocodeInput {
  margin-bottom: 0;
}

.promocodeInfoText {
  padding-top: 0.25rem;
}

.dateTimeTitle {
  width: auto !important;
  border: none !important;
  margin-top: 2.625rem;
}

.pointsInfoTextContainer {
  padding: 0.5rem 0rem;
}

.spaceBetweenContainer {
  display: flex;
  justify-content: space-between;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  margin-top: 1rem;
  margin-bottom: 0;
}

.dateTimeValueContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.sectionListTitle {
  margin-bottom: 1rem;
  margin-top: 2.625rem;
}

.sectionListTitleRight {
  text-align: right;
  margin-bottom: 1rem;
  margin-top: 2.625rem;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.buttonsContainerDashboard {
  display: flex;
  justify-content: space-between;
}

.rightButton.rightButton {
  align-self: flex-end;
  margin-top: 3rem;
  padding-left: 4rem;
  padding-right: 4rem;
  margin-bottom: 3.125rem;
  margin-bottom: 1rem;
}

.leftButton.leftButton {
  margin-top: 3rem;
  padding-left: 4rem;
  padding-right: 4rem;
  float: left;
  margin-bottom: 3.125rem;
  display: flex;
  align-self: flex-start;
}

.cancelButtonText {
  display: inline-flex;
  justify-content: center;
  color: grey;
  text-decoration: underline;
  align-items: center;
  margin-left: 1rem;
  cursor: pointer;
}

.header {
  color: var(--color-brand-1000);
}

.childTreatment {
  margin-left: 1rem;
}

@media (max-width: 576px) {
  .sectionListTitle {
    margin-bottom: 0.5rem;
  }

  .sectionListTitleRight {
    margin-bottom: 0.5rem;
  }

  .buttonsContainer {
    flex-direction: column;
    margin: 1rem 0;
  }

  .rightButton.rightButton,
  .leftButton.leftButton {
    float: none;
    margin: 1rem auto;
  }
}

.infobox {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.flexTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.infoSymbol {
  border-color: var(--color-brand-1000);
  background-image: url('../../../../assets/images/info_icon_white.svg');
}

.discountWrapper {
  position: relative;
  display: block;
  padding-right: 0.4rem;
  text-decoration: line-through;
}

.lineThrough {
  display: inline-block;
  height: 2px;
  background-color: var(--color-primary);
  width: 100%;
  position: absolute;
  transform: rotate(-20deg);
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.servicePrice {
  text-align: right;
  /* color: var(--color-brand-1000); */
  user-select: none;
}

.priceWrapper {
  display: flex;
}

.olioPointsText {
  font-size: var(--font-size-base);
  padding-bottom: 0.75rem;
}
