.serviceRow {
  position: relative;
  padding: 1rem;
  border: 1px solid var(--color-primary);
  border-radius: 4px;
  margin-bottom: 0.8rem;
  overflow: visible !important;
  &:hover {
    cursor: pointer;
  }
}

.serviceItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.2rem;
}

.serviceItemWrapper {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.serviceDescription {
  margin: 0;
  margin-top: 0.1rem;
  padding: 0;
  margin-left: 2.15em;
  font-size: 0.9rem;
  color: var(--color-text-foreground);
}

.additionalServices {
  margin-top: 1rem;
}

.serviceTitle {
  font-weight: 700;
  margin: 0;
}

.badge {
  position: absolute;
  top: -10px;
  right: -8px;
  padding: 0.3rem 0.7rem;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 600;

  opacity: 0; /* Start hidden */
  transform: scale(0.7); /* Start slightly smaller */
  transition: opacity 0.2s ease, transform 0.2s ease !important;
}

.mostPopularBadge {
  background-color: var(--color-primary);
  color: var(--color-brand-1000);
}
.newsBadge {
  background-color: var(--color-hover-100);
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
}

.showBadge {
  opacity: 1;
  transform: scale(1);
}

.servicePrice {
  text-align: right;
  color: var(--color-text-foreground);
  user-select: none;
}

.childServiceContainer {
  padding: 0.6rem 0;
  display: flex;
  justify-content: space-between;
  &:hover {
    background-color: var(--color-hover-100);
    border-radius: 6px;
  }
}

.childService {
  margin-left: 2rem;
}

.discountWrapper {
  position: relative;
  display: block;
  margin-right: 0.6rem;
}

.lineThrough {
  display: inline-block;
  height: 2px;
  background-color: var(--color-text-foreground);
  width: 100%;
  position: absolute;
  transform: rotate(-20deg);
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

@media (max-width: 768px) {
  .treatmentPrice {
    margin-left: 0;
  }

  .buttonContainer {
    width: 80%;
  }
}

@media (max-width: 576px) {
  .buttonContainer {
    width: 82%;
  }
}

.bodyText {
  font-size: var(--font-size-body);
  line-height: 1.5rem;
  margin: 0;
}
