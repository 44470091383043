.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.innerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.content {
  font-weight: 400;
  margin-left: 0.5em;
  user-select: none;
  color: var(--color-text-foreground);
}

.contentSelect {
  font-weight: 600;
}

.selected {
  font-weight: bold;
}

.infoBox {
  margin-left: 0.5em;
}
