.upComingBookingsContainer {
  margin-bottom: 4rem;
}

.hasNoBooking {
  color: var(--color-primary);
  text-align: center;
}

.bookingButton {
  width: 100%;
  background-color: var(--color-white);
}
