.container {
  max-width: 600px;
  margin: 0 auto;
}

.wrapper {
  text-align: center;
}

.header {
  margin-top: 2rem;
  color: var(--color-light-100);
}

.alert {
  width: 100%;
}

.formAlert {
  min-height: 0 !important;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
}
