.root {
  position: fixed;
  background-color: var(--color-light-100);
  z-index: 9999;
  transform: translateX(5%);
  width: 90%;
  max-width: 500px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.rootHidden {
  display: none;
}

.content {
  height: 100%;
  padding: 1em;
  font-size: var(--font-size-body);
  font-weight: var(--font-weight-light);
  background-color: var(--color-primary);
  color: var(--color-brand-1000);
  border-radius: 6px;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(2px);
  z-index: 1000;
}

.overlayHidden {
  display: none;
}

.exitButton {
  position: absolute;
  top: 1em;
  right: 1em;
  height: 12px;
  width: 12px;
  padding: 12px;

  background-color: transparent;
  border: none;
  background-image: url('../../../assets/images/Mobile_Cross_White.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 12px 12px;
}

.exitButton:hover {
  cursor: pointer;
}
