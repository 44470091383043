.link {
  text-decoration: none;
}

.linkUnderlined {
  text-decoration: underline;
}

.linkUnderlined:hover {
  text-decoration: none;
}

.primary {
  color: var(--color-text-foreground);
}

.secondary {
  color: var(--color-light-100);
}

.tertiary {
  color: var(--color-brand-100);
}
