.container {
  background-color: var(--color-brand-1000);
  color: var(--color-primary);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  margin-bottom: 0;
}

.noMarginBottom {
  margin-bottom: 0 !important;
}

.buttonGroupWrapper {
  display: flex;
  justify-content: center;
}

.buttonWrapper {
  margin-block: 1rem;
  margin-inline: 0.5rem;
}

.guestMemberTextWrapper {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.linkCopiedText {
  position: absolute;
  color: var(--color-primary);
  bottom: -0.25rem;
  left: 0;
  right: 0;
  margin: 0 !important;
}

.memberButtonWrapper {
  position: relative;
}

@media (max-width: 576px) {
  .guestInformation {
    margin-top: 2rem;
  }
}

.warningListContainer {
  display: flex;
  justify-content: center;
}

.warningList {
  text-align: left;
}

.noshowBanner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  /* width: 100%; */
  margin: auto;

  border-radius: 8px;
  border: 2px solid var(--color-primary);
}

.bannerText {
  font-size: medium;
  margin: 0;
  padding: 0;
}

.welcomeText {
  margin: 1rem !important;
}
