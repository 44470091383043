:root {
  /* Colors */
  --color-brand-100: #f5634c;
  --color-brand-400: #f9da6a;
  --color-brand-700: #31a894;
  --color-brand-800: #d8fcfa;
  --color-brand-900: #fc614d;
  --color-brand-1000: #fff9f4;
  --color-light-100: #fff9f5;
  --color-light-200: #fff9f5;
  --color-light-400: #a8a39e;

  --color-hover-100: #faedee;

  --color-primary: #8e4f59;
  --color-secondary: hsl(355, 57%, 92%);
  --color-text-foreground: #3d0d11;

  --color-white: #fff;

  /* Fonts */
  --font-family: 'GT-Walsheim', Helvetica, sans-serif;

  /* Font Sizes */
  --font-size-base: 16px;
  --font-size-h1: 2.1875rem;
  --font-size-h2: 1.6875rem;
  --font-size-h3: 1.375rem; /* 22px */
  --font-size-h4: 1rem;
  --font-size-body: 1.0625rem;
  --font-size-small: 0.75rem;

  /* Font Weights */
  --font-weight-light: light;
  --font-weight-normal: normal;
  --font-weight-medium: medium;
  --font-weight-bold: bold;

  /* Global sizes*/
  --size-form-height: 45px;

  /* zIndex */
  --z-index-9999: 9999;
}
