.container {
  margin-bottom: 4rem;
  min-height: 300px;
}

.title {
  color: var(--color-text-foreground);
}

.bodyText {
  color: var(--color-text-foreground);
}

.loadingContainer {
  height: 200px;
  text-align: center;
}

.divider {
  width: 100%;
  height: 0.2rem;
  background-color: var(--color-primary);
  margin-bottom: 1rem;
}

.noAvailableSlots {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.horizontalFlex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.additionalButtonWrapper {
  min-width: 180px;
}
.additionalText {
  color: var(--color-primary);
  margin-bottom: 1.5rem !important;
}

.additionalLoader {
  top: -2.5em;
}

.dayWrapper {
  margin-top: 3rem;
  padding-bottom: 0.5rem !important;
}
.timeSection {
  margin-top: 1.5rem;
}
.header {
  padding-bottom: 1rem;
  width: 100%;
}
.timeSlotList {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr !important;
  justify-content: space-between !important;
  justify-items: center !important;
  flex-wrap: wrap !important;
  column-gap: 1rem;
  row-gap: 1rem;
}
@media (max-width: 768px) {
  .timeSlotList {
    grid-template-columns: 1fr 1fr 1fr !important;
    column-gap: 0.75rem;
    row-gap: 1rem;
  }
}
