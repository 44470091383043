.modalContent {
  display: flex;
  flex-direction: column;
}

.borderBox {
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 100%;
  border-radius: 8px;
  border: 2px solid var(--color-primary);
}

.flexContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.summaryColumn {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  color: var(--color-primary);
}

.summaryColumn > * {
  margin: 0 !important;
  padding: 0;
}

.flexBetween {
  display: flex;
  justify-content: space-between;
}

.iconUnderlineButton {
  display: flex;
  align-items: center;
  gap: 2px;
  text-decoration: underline;
  color: var(--color-primary);
}

.iconUnderlineButton > * {
  margin: 0 !important;
  padding: 0;
}

.iconUnderlineButton:hover {
  cursor: pointer;
}

.strippedButton {
  background-color: transparent;
  border: none;
  color: var(--color-primary);
}

.strippedButton:hover {
  cursor: pointer;
}

.flexGap {
  display: flex;
  align-items: baseline;
  gap: 0.5rem;
}

.flexGap > * {
  margin: 0 !important;
  padding: 0;
}

.iconTextContainer {
  display: flex;
  gap: 0.5rem;
  color: var(--color-primary);
}

.iconTextContainer > * {
  margin: 0 !important;
  padding: 0;
}

.rebookingOverviewSection {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-bottom: 2rem;
}

.buttonGroup {
  display: flex;
  gap: 0.5rem;
}

.buttonGroup > button {
  flex: 1;
}

.userRoleSection {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.userRoleButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 1rem;
  border-radius: 12px;
  border: none;

  background-color: white;
  color: var(--color-primary);
}

.userRoleButton:hover {
  cursor: pointer;
}

.primaryButton {
  color: var(--color-brand-1000);
  background-color: var(--color-primary);
}

.secondaryButton {
  background-color: var(--color-brand-1000);
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
}

.thin {
  font-weight: 300;
}

.loadingTextIndicator {
  opacity: 0.5;
}

.outlinedDiv {
  display: flex;
  justify-content: center;
  padding: 16px;
  align-items: center;

  margin-top: 1rem;

  border-radius: 8px;
  border: 2px solid var(--color-primary);
  color: var(--color-primary);
}

.outlinedDiv > * {
  margin: 0 !important;
}

.underline {
  text-decoration: underline;
  color: var(--color-primary);
}

.containerOverride {
  padding: 1rem 0.2rem;
}
