.container {
  position: relative;
  margin-bottom: 3rem;
  width: 100%;
}

.input {
  box-shadow: none;
  padding: 0 1rem;
  outline: none;
  border: none;
  width: 100%;
  font-size: var(--font-size-body);
  font-family: var(--font-family);
  border-radius: var(--size-form-height);
  height: var(--size-form-height);
  background-color: var(--color-light-100);
  box-sizing: border-box;
  color: var(--color-text-foreground);
  background-color: var(--color-white);
}

.label {
  position: absolute;
  top: 0.7rem;
  transition: 0.4s;
  cursor: text;
  pointer-events: none;
  font-size: var(--font-size-body);
  padding-left: 1rem;
  left: 0;
}

.inputPrimary {
  color: var(--color-text-foreground);
}

.inputSecondary {
  color: var(--color-brand-100);
}

.labelPrimary {
  color: var(--color-text-foreground);
}

.labelSecondary {
  color: var(--color-primary);
}

input:focus ~ label,
input:valid ~ label {
  top: -1.5rem;
}
