.exitbutton {
  position: absolute;
  right: 25px;
  margin-top: 25px;
  z-index: 10;
  height: 17px;
  width: 17px;
  background-image: url('../../../assets/images/Desktop_Cross_White.svg');
  background-repeat: no-repeat;
  background-size: 17px 17px;
}

.loadingIndicator {
  color: var(--color-light-100);
}

.updateBookingButton {
  margin-top: 2rem;
}
