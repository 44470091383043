.button {
  position: relative;
  font-weight: var(--font-weight-medium);
  width: 100%;
  padding: 12px 20px;
  border: solid;
  border-width: 2px;
  border-radius: 100px;
  border-color: var(--color-primary);
  display: flex;
  align-items: center;
  justify-content: center;
}

.discountBadge {
  position: absolute;
  top: -10px;
  right: -14px;
  background-color: var(--color-primary);
  padding: 0.3rem 0.7rem;
  color: var(--color-brand-1000);
  border-radius: 8px;
  font-size: 0.8rem;
  font-weight: 600;
  border: 1px solid var(--color-brand-1000);
}

.button:hover {
  cursor: pointer;
}

.unselected {
  background-color: transparent;
  color: var(--color-primary);
}

.unselected:hover {
  background-color: var(--color-primary);
  color: var(--color-brand-1000);
}

.selected {
  background-color: var(--color-primary);
  color: var(--color-brand-1000);
  box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.08);
}
.unavailable {
  background-color: transparent;
  color: var(--color-primary);
  opacity: 0.25;
}

.unavailable:hover {
  cursor: default;
}
