/* 
Oransj: var(--color-brand-100);
Hvit: var(--color-light-100);
Lilla: var(--color-primary);
*/

.smallText {
  font-size: 0.8rem;
}

.bookingOverviewSection {
  display: flex;
  flex-direction: column;
  background-color: var(--color-brand-1000);
}

.bookingOverviewContainer {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 1rem;
  gap: 2rem;
  background-color: var(--color-primary);
  color: var(--color-light-100);
}

.bookingOverviewContainer * {
  margin: 0 !important;
}

/* ICON ROW */
.iconRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

/* BOOKING DETAILS */
.bookingDetails {
  display: flex;
  flex-direction: column;
}

.dateTimeContainer {
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.25rem;
}

/* SERVICES OVERVIEW */
.servicesOverviewContainer {
  background-color: var(--color-brand-1000);
  color: var(--color-primary);
  border-radius: 0.5rem;

  display: flex;
  flex-direction: column;
  padding: 0.5rem;
}

.horizontalRule {
  border: 1px solid var(--color-primary);
  opacity: 0.5;
  margin: 0.75rem 0 !important;
  width: 100%;
}

/* SERVICE ROW */
.serviceRowContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  /* padding: 0 0.5rem; */
}

.serviceNameContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex-basis: 70%;
  color: var(--color-text-foreground);
}

.servicePriceDurationContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-basis: 30%;
}

.sumContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 0.25rem;
  padding-bottom: 0.5rem;
}

.sumLine {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.serviceRow {
  padding: 0 0.5rem;
}

/* PROMOCODE */
.promocodeContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.underline {
  text-decoration: underline;
  color: var(--color-brand-1000);
}

.strippedButton {
  background-color: transparent;
  border: none;
  color: var(--color-light-100);
}

.strippedButton:hover {
  cursor: pointer;
}

.underline:hover {
  cursor: pointer;
}

.promocodeInput {
  background-color: var(--color-brand-1000);
  color: var(--color-primary);
  padding: 0.5rem;
}

/* BOOK BUTTON */
.bookSectionContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.infoContainer {
  display: inline-block;
  padding-bottom: 0.25rem;
  /* font-size: 0.8rem; */

  /* vertical-align: middle; */
}

.infoBoxContainer > * {
  display: inline-block;
  vertical-align: middle;
}

.infoIcon {
  padding-left: 0.25rem;
  vertical-align: middle;
}

.bookButton {
  display: flex;
  flex: 1;
  justify-content: center;
  background-color: var(--color-brand-1000);
  color: var(--color-primary);
  border: none;
  padding: 1rem;
  border-radius: 5rem;
}

.bookButton:hover {
  cursor: pointer;
  opacity: 0.9;
  transition: all 0.15s ease-in-out;
}

.bookButton:active {
  transform: scale(0.99);
}

.bookButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.underline {
  text-decoration: underline;
}

/* INVALID SECTIONS */

.invalidSections {
  display: flex;
  flex-direction: column;
  color: var(--color-brand-1000);
  padding: 0;
  margin-top: 0.5rem;
}

.invalidSections * {
  margin: 0.25rem 0 !important;
}

/* MISC */

.strikeThrough {
  text-decoration: line-through;
}

.servicesWithPromocode {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.loadingContainer * {
  color: var(--color-light-100) !important;
}

.loadingWrapper {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 3rem;
}

.loadingWrapper p {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-light-100) !important;
}

.deleteContainer {
  margin: 2rem 0;
}

.loginPageContainer {
  margin: 2rem 0;
}

.loginPageContainer > * {
  width: 100%;
}

.loginPageContainer * {
  font-size: 1rem;
  margin: 0.5rem 0 !important;
  gap: 0.5rem;
}

.loginPageContainer button {
  margin: 0 !important;
}

.loginOptions {
  display: flex;
  justify-content: center;
  margin: 2rem 0;
}

.loginOptionButton {
  display: flex;
  margin-bottom: 1rem;
}

.loginOptionContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2rem;
  gap: 0.5rem;
}

.loginOptionContainer * {
  gap: 0.5rem;
}

.text {
  text-align: center;
  color: var(--color-primary);
}

.twoButtonWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 1rem;
}

/* CANCEL BOOKING */
.deleteContainer {
  margin: 2rem 0;
}

.deleteConfirmationText {
  color: var(--color-primary);
  margin-bottom: 0.5rem !important;
}

.deleteButtonsContainer {
  display: flex;
  gap: 1rem;
}

.cancelButton {
  margin-top: 2rem;
  color: var(--color-primary);
}

.light {
  color: var(--color-light-100);
}
