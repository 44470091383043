.container {
  position: fixed;
  top: 0;
  background-color: var(--color-primary);
  height: 90px;
  width: 100vw;
  z-index: 10;
}
.giftCardBanner {
  position: relative;
  top: 90px;
  background-color: #ad6c76;
  height: 45px;
  width: 100vw;
  z-index: 8;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding-block: 24px;
  padding-inline: 20px;
  max-width: 1200px;
}

.logoWrapper {
  margin-right: 32px;
}
.leftContainter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mobileMenuButtonWrapper {
  display: none;
}
.rightContainer {
  display: flex;
  align-items: center;
}

.routeLink,
.registerOrMemberButton {
  margin-right: 1rem;
  color: var(--color-light-100);
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  font-size: 18px;
}

.mobileMenuContainer {
  margin-top: 0.5rem;
  display: none;
}

.mobileMenuModalOverlay {
  background-color: var(--color-brand-1000);
  z-index: 2;
  width: 100vw;
  height: 100%;
  top: 90px;
  left: 0;
  text-align: left;
  position: fixed;
}

.text {
  color: var(--color-primary);
  user-select: none;
  font-weight: bold;
}

.listItem {
  list-style-type: none;
  margin-bottom: 2rem;
  animation-duration: 0.5s;
  animation-name: slidein;
  font-weight: bold;
}

.mobileMenuContent {
  padding-left: 2rem;
  padding-top: 3rem;
}

.line {
  width: 50px;
  height: 3px;
  background-color: var(--color-brand-100);
}

.externalLink {
  font-size: var(--font-size-h2);
  font-weight: var(--font-weight-medium);
  color: var(--color-primary);
  text-decoration: none;
  display: inline-block;
  margin-bottom: 1rem;
  user-select: none;
}

@media (max-width: 1020px) {
  .linkWrapper {
    display: none;
  }
}
@media (max-width: 768px) {
  .rightContainer {
    display: none;
  }
  .mobileMenuContainer {
    display: block;
  }
  .mobileMenuButtonWrapper {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
}
.giftCardText {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  color: var(--color-light-100);
  text-decoration: none;
  cursor: pointer;
  text-align: center;
}

@media (min-width: 576px) {
  .giftCardTextDesktop {
    display: block;
  }
  .giftCardTextMobile {
    display: none;
  }
}
@media (max-width: 576px) {
  .giftCardTextDesktop {
    display: none;
  }
  .giftCardTextMobile {
    display: block;
  }
}

@keyframes slidein {
  from {
    margin-left: -20px;
  }

  to {
    margin-left: 0%;
  }
}

.giftCardBanner {
  position: relative;
  background-color: var(--color-text-foreground); /* Background color for the banner */
  height: 100px; /* Increased height for content */
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 8;
}

.bannerContent {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px; /* Space between call-to-action and timer */
}

.callToAction {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 16px;
  font-weight: bold;
  color: white; /* Text color for the call-to-action */
  margin-bottom: 4px;
}

.timerContainer {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
}

.timerElement {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  background-color: white; /* White background for timer elements */
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  min-width: 50px;
}

.timerValue {
  font-size: 16px;
  font-weight: bold;
  color: #333; /* Text color for timer values */
}

.timerLabel {
  font-size: 12px;
  color: #666; /* Text color for timer labels */
  text-transform: uppercase;
}
