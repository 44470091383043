.alert {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding: 1rem;
  border-radius: 5px;
  color: white;
  line-height: 1.5rem;
  position: relative;
  margin: 1rem 0;
}

.alertSuccess {
  background-color: var(--color-brand-700);
}

.alertDanger {
  background-color: var(--color-brand-100);
}

.alertContent {
  display: block;
  margin: auto auto;
  text-align: center;
}
.text {
  color: var(--color-brand-1000);
  margin-bottom: 0;
}

.closeButton {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem;
  cursor: pointer;
  border: none;
  background-color: transparent;
  font-size: 1.5rem;
  color: var(--color-light-100);
}
