.title {
  text-align: center;
}

.explanationText {
  text-align: center;
  display: flex;
  margin-bottom: 2rem;
  color: var(--color-primary);
}
