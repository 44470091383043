.childWrapper {
  position: relative;
  top: 110px;
  margin-top: 1rem;
  padding-bottom: 1.5rem;
}

.menuContainer {
  display: none;
}

.footerBarCollection {
  position: fixed;
  bottom: 0;
  z-index: var(--z-index-9999);
  width: 100vw;
}

@media (max-width: 768px) {
  .footerBarCollection {
    bottom: unset;
    top: 0;
  }
}
