.message {
  margin: 0 auto;
  text-align: center;
}

.callToActionsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
}

.orText {
  margin: 1rem 0;
  color: var(--color-primary);
}

.title {
  color: var(--color-primary);
}
